import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Button, makeStyles } from '@material-ui/core';
import htmlToDraft from 'html-to-draftjs';
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Redirect, Link } from 'react-router-dom';
import { JOB_DATA } from '../../redux/state';
import {
  buttonRed,
  buttonRedHover,
  inactiveGray,
  neuBlue,
  successGreen,
  successLightGreen, white,
} from '../../colors';

const useStyles = makeStyles(() => ({
  root: {
    width: '80%',
    margin: 'auto',
  },
  titleContainer: {
    marginTop: '75px',
  },
  titleText: {
    color: `${neuBlue}`,
    fontFamily: 'QuicksandMedium',
    textAlign: 'center',
  },
  titleLine: {
    width: '50px',
    height: '3px',
    background: `${neuBlue}`,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '15px',
    borderRadius: '2px',
  },
  headerRow: {
    marginTop: '70px',
    marginBottom: '30px',
  },
  headerRowTextContainer: {
    display: 'inline-block',
  },
  entityRowTitle: {
    fontFamily: 'QuicksandBold',
    wordBreak: 'break-word',
  },
  radioContainer: {
    display: 'flex',
    alignItems: 'center',
    float: 'right',
    '& input': {
      width: '15px',
      height: '15px',
    },
  },
  radioText: {
    fontFamily: 'QuicksandMedium',
    fontSize: '14px',
    marginLeft: '10px',
    marginRight: '10px',
  },
  addNewJobInput: {
    width: '100%',
    padding: '10px',
    border: `1px solid ${inactiveGray}`,
    borderRadius: '5px',
    marginBottom: '40px',
    fontSize: '14px',
    fontFamily: 'QuicksandMedium',
    outline: 'none',
  },
  editor: {
    border: `1px solid ${inactiveGray}`,
    borderRadius: '5px',
  },
  editorToolbar: {
    borderRadius: '5px !important',
    marginBottom: '0 !important',
    borderBottom: `1px solid ${inactiveGray} !important`,
  },

  editorText: {
    background: `${white}`,
    padding: '10px',
    borderRadius: '5px',
    fontFamily: 'QuicksandRegular',
    fontSize: '14px',
  },

  saveButton: {
    marginTop: '50px',
    marginBottom: '100px',
    width: '100px',
    fontSize: '14px',
    fontFamily: 'QuicksandMedium',
    color: `${white} !important`,
    backgroundColor: `${successGreen} !important`,
    borderColor: `${successGreen} !important`,
    textTransform: 'none !important',
  },

  buttonContainer: {
    display: 'flex',
    margin: 'auto',
    justifyContent: 'center',

    '& .Mui-disabled': {
      backgroundColor: `${successLightGreen} !important`,
    },
  },
  buttonCancel: {
    marginTop: '50px',
    marginBottom: '100px',
    width: '100px',
    fontSize: '14px',
    fontFamily: 'QuicksandMedium',
    color: `${white} !important`,
    backgroundColor: `${buttonRed}`,
    textTransform: 'none !important',
    '&:hover': {
      backgroundColor: `${buttonRedHover}`,
    },
    marginLeft: '50px',
  },
  descriptionEditor: {
    '& .rdw-fontsize-wrapper': {
      display: 'none',
    },
    '& .rdw-fontfamily-wrapper': {
      display: 'none',
    },
  },
  textFieldInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    fontWeight: 'bold',
    color: buttonRed,
    marginBottom: '50px',
    marginLeft: '10px',
  },
  shortDescription: {
    marginBottom: '0px !important',
  },
}));

const AddJob = ({
  id,
  shouldRedirect,
  isEnabled,
  title,
  descriptionTitle,
  descriptionText,
  onFetchJobById,
  onUpdateField,
  onUpdateJob,
  onAddJob,
  jobLocation,
  referenceNumber,
  resetJobState,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const classes = useStyles();

  useEffect(() => {
    if (window.location.pathname !== '/add-new-job') {
      const location = window.location.pathname;
      const jobId = location.substring(location.lastIndexOf('/') + 1);
      onFetchJobById(jobId);
    }
  }, []);

  useEffect(() => {
    const newEditorState = EditorState.createWithContent(
      ContentState.createFromBlockArray(
        htmlToDraft(descriptionText),
      ),
    );
    setEditorState(newEditorState);
  }, [id]);

  useEffect(() => () => {
    resetJobState();
  }, []);

  const addFirstQuoteWhenSkipped = (style) => {
    if (style.indexOf('fontfamily-') === 0 && style.indexOf("'") >= 0) {
      // eslint-disable-next-line no-param-reassign
      style = `fontfamily-'${style.substring('fontfamily-'.length)}`;
    }
    return style;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const job = {
      title,
      descriptionTitle,
      descriptionText,
      jobStatus: isEnabled,
      id,
      jobLocation,
      referenceNumber,
    };

    if (window.location.pathname !== '/add-new-job') {
      return onUpdateJob(job);
    }
    return onAddJob(job);
  };

  const replaceDoubleQuotesWithSingleQuote = (style) => style.replace(/"/g, "'");

  const handleTitleChange = (e) => onUpdateField(JOB_DATA, 'title', e.target.value);
  const handleJobLocation = (e) => onUpdateField(JOB_DATA, 'jobLocation', e.target.value);
  const handleDescriptionChange = (e) => onUpdateField(JOB_DATA, 'descriptionTitle', e.target.value);
  const handleReferenceNumberChange = (e) => onUpdateField(JOB_DATA, 'referenceNumber', e.target.value);
  const updateJobStatus = () => onUpdateField(JOB_DATA, 'isEnabled', !isEnabled);

  const escapeQuotesInlineStyling = (currentContent) => {
    // fix for convertToRaw method, which doesn't escape double quotes inside inline styling,
    // so the styling in corrupted
    currentContent.blocks.forEach((block) => {
      block.inlineStyleRanges.forEach((inlineStyle) => {
        // eslint-disable-next-line no-param-reassign
        inlineStyle.style = replaceDoubleQuotesWithSingleQuote(inlineStyle.style);
        // eslint-disable-next-line no-param-reassign
        inlineStyle.style = addFirstQuoteWhenSkipped(inlineStyle.style);
      });
    });
    return currentContent;
  };

  const handleTextChange = (newEditorState) => {
    const currentContent = escapeQuotesInlineStyling(
      convertToRaw(newEditorState.getCurrentContent()),
    );
    setEditorState(newEditorState);
    onUpdateField(JOB_DATA, 'descriptionText', draftToHtml(currentContent));
  };

  const validateFields = () => {
    const fields = [title, descriptionTitle, descriptionText];
    return fields.every((field) => field.length > 0 && field !== '<p></p>\n');
  };

  if (shouldRedirect) {
    return (<Redirect to="/jobs" />);
  }

  return (
    <div className={classes.root}>

      <div className={classes.titleContainer}>
        <h1 className={classes.titleText}>
          {window.location.pathname
                    !== '/add-new-job' ? 'Edit Job'
            : 'Add New Job'}
        </h1>
        <div className={classes.titleLine} />
      </div>

      <div className={classes.headerRow}>
        <div className={classes.headerRowTextContainer}>
          <p className={classes.entityRowTitle}>Job Details</p>
        </div>
        <form className={classes.radioContainer}>
          <input
            type="radio"
            name="job-status"
            value="active"
            checked={isEnabled}
            onChange={updateJobStatus}
          />
          <span className={classes.radioText}>Active</span>
          <input
            type="radio"
            name="job-status"
            value="inactive"
            checked={!isEnabled}
            onChange={updateJobStatus}
          />
          <span className={classes.radioText}>Inactive</span>
        </form>
      </div>

      <form onSubmit={handleSubmit}>
        <input
          type="text"
          className={classes.addNewJobInput}
          placeholder="Enter Job Title"
          onInput={handleTitleChange}
          defaultValue={title}
        />
        <input
          type="text"
          className={classes.addNewJobInput}
          placeholder="Enter Location / Department"
          onInput={handleJobLocation}
          defaultValue={jobLocation}
        />
        <input
          type="text"
          className={classes.addNewJobInput}
          placeholder="Enter Reference number"
          onInput={handleReferenceNumberChange}
          defaultValue={referenceNumber}
        />
        <textarea
          maxLength="5000"
          className={`${classes.addNewJobInput} ${classes.shortDescription}`}
          placeholder="Enter Short Description (for internal use only)"
          onInput={handleDescriptionChange}
          value={descriptionTitle}
          rows="4"
        />
        <div className={classes.textFieldInfo}>
          <span>Not Public</span>
        </div>

        <div className={classes.descriptionEditor}>
          <Editor
            editorState={editorState}
            toolbarClassName={classes.editorToolbar}
            wrapperClassName={classes.editor}
            editorClassName={classes.editorText}
            onEditorStateChange={handleTextChange}
            placeholder="Enter Job Overview"
            stripPastedStyles
          />
        </div>

        <div className={classes.buttonContainer}>
          <Button
            type="submit"
            className={classes.saveButton}
            disabled={!validateFields()}
          >
            Save
          </Button>

          <Button
            className={classes.buttonCancel}
            component={Link}
            to="/jobs"
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddJob;
